import { api } from '@/api';
import router from '@/router';
import { getLocalToken, removeLocalToken, saveLocalToken } from '@/utils';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { State } from '../state';
import {
    commitAddNotification,
    commitRemoveNotification,
    commitSetLoggedIn,
    commitSetLogInError,
    commitSetToken,
    commitSetUserProfile,
    commitSetCalendars,
    commitSetPlanningConfiguration,
    commitSetPhysiotherapyUsers
} from './mutations';
import { commitSetPatients } from '@/store/patients/mutations';
import { AppNotification, MainState } from './state';

type MainContext = ActionContext<MainState, State>;

export const actions = {
    async actionLogIn(context: MainContext, payload: { username: string; password: string, otp: string | null }) {
        try {
            const response = await api.logInGetToken(payload.username, payload.password, payload.otp);
            const token = response.data.access_token;
            if (token) {
                saveLocalToken(token);
                commitSetToken(context, token);
                commitSetLoggedIn(context, true);
                commitSetLogInError(context, false);
                await dispatchGetUserProfile(context);
                await dispatchRouteLoggedIn(context);
                commitAddNotification(context, { content: 'Ingelogd', color: 'success' });
            } else {
                await dispatchLogOut(context);
            }
        } catch (err) {
            commitSetLogInError(context, true);
            await dispatchLogOut(context);
        }
    },
    async actionLogInOnBehalfOf(context: MainContext, onBehalfOf: string) {
        try {
            const response = await api.getTokenOnBehalfOf(context.state.token, onBehalfOf);
            const token = response.data.access_token;
            if (token) {
                saveLocalToken(token);
                commitSetToken(context, token);
                commitSetLoggedIn(context, true);
                commitSetLogInError(context, false);
                await dispatchGetUserProfile(context);
                await dispatchRouteLoggedIn(context);
                commitAddNotification(context, { content: 'Ingelogd', color: 'success' });
            } else {
                await dispatchLogOut(context);
            }
        } catch (err) {
            commitSetLogInError(context, true);
            await dispatchLogOut(context);
        }
    },
    async actionGetUserProfile(context: MainContext ) {
        try {
            const response = await api.getMe(context.state.token);
            if (response.data) {
                commitSetUserProfile(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetUserProfileWithGoogleValidation(context: MainContext ) {
        try {
            const response = await api.getMe(context.state.token, true);
            if (response.data) {
                commitSetUserProfile(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetPhysiotherapists(context: MainContext) {
        try {
            const physiotherapyUsers = await api.getPhysiotherapyUsers(context.state.token);
            if (physiotherapyUsers.data) {
                commitSetPhysiotherapyUsers(context, physiotherapyUsers.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetPlanningConfiguration(context: MainContext) {
        try {
            const response = await api.getPlannerConfiguration(context.state.token);
            if (response.data) {
                commitSetPlanningConfiguration(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdatePlanningConfiguration(context: MainContext, payload) {
        const response = (await Promise.all([
            api.updatePlannerConfiguration(context.state.token, payload),
            await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
        ]))[0];
        commitSetPlanningConfiguration(context, response.data);
    },
    async actionUpdateUserProfile(context: MainContext, payload) {
        try {
            const loadingNotification = { content: 'laden', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateMe(context.state.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUserProfile(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Profiel bewerkt', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetGoogleCalendars(context: MainContext) {
        try {
            const calendars = await api.getCalendars(context.state.token);
            if (calendars.data) {
                commitSetCalendars(context, calendars.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCheckLoggedIn(context: MainContext) {
        if (!context.state.isLoggedIn) {
            let token = context.state.token;
            if (!token) {
                const localToken = getLocalToken();
                if (localToken) {
                    commitSetToken(context, localToken);
                    token = localToken;
                }
            }
            if (token) {
                try {
                    const response = await api.getMe(token);
                    commitSetLoggedIn(context, true);
                    commitSetUserProfile(context, response.data);
                } catch (error) {
                    await dispatchRemoveLogIn(context);
                }
            } else {
                await dispatchRemoveLogIn(context);
            }
        }
    },
    async actionRemoveLogIn(context: MainContext) {
        removeLocalToken();
        commitSetToken(context, '');
        commitSetLoggedIn(context, false);
    },
    async actionLogOut(context: MainContext) {
        await dispatchRemoveLogIn(context);
        await dispatchRouteLogOut(context);
    },
    async actionUserLogOut(context: MainContext) {
        await dispatchLogOut(context);
        commitAddNotification(context, { content: 'Uitgelogd', color: 'success' });
    },
    actionRouteLogOut(context: MainContext) {
        if (router.currentRoute.path !== '/login') {
            router.push('/login');
        }
    },
    async actionCheckApiError(context: MainContext, payload: any) {
        if (payload.response?.status === 401) {
            await dispatchLogOut(context);
        }
    },
    actionRouteLoggedIn(context: MainContext) {
        if (router.currentRoute.path === '/login' || router.currentRoute.path === '/') {
            router.push('/main').catch(() => { });
        }
    },
    async removeNotification(context: MainContext, payload: { notification: AppNotification, timeout: number }) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                commitRemoveNotification(context, payload.notification);
                resolve(true);
            }, payload.timeout);
        });
    },
    async passwordRecovery(context: MainContext, payload: { username: string }) {
        const loadingNotification = { content: 'Email wachtwoord verzenden', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.passwordRecovery(payload.username),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Email wachtwoord verzonden', color: 'success' });
            await dispatchLogOut(context);
        } catch (error) {
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { color: 'error', content: 'Gebruikersnaam foutief' });
        }
    },
    async resetPassword(context: MainContext, payload: { password: string, token: string }) {
        const loadingNotification = { content: 'Wachtwoord herinstellen', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.resetPassword(payload.password, payload.token),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Wachtwoord opnieuw ingesteld', color: 'success' });
            await dispatchLogOut(context);
        } catch (error) {
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { color: 'error', content: 'Fout bij instellen wachtwoord' });
        }
    },
    async actionUpdateGoogleCalendar(context: MainContext, calendar_id: string) {
        try {
            const response = (await Promise.all([
                api.putCalendar(context.rootState.main.token, calendar_id),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGoogleAuthorizationToken(context: MainContext, code: string) {
        try {
            const response = (await Promise.all([
                api.setGoogleAuthorizationToken(context.rootState.main.token, code),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },


    async actionRegisterEHealth(context: MainContext, payload: { ssin: string, passphrase: string, certificate: File }) {
        try {
            await api.registerEHealth(context.rootState.main.token, payload.ssin, payload.passphrase, payload.certificate);
            commitAddNotification(context, { content: 'eHealth geconfigureerd', color: 'success' });
        } catch (error: any) {
            if (error.response) {
                let error_message = error.response.data.detail;
                if (error_message === "Invalid certificate or wrong password") {
                    error_message = "Foutief wachtwoord of ongeldig certificaat"
                }
                if (error_message.includes("DerInputStream")) {
                    error_message = "bestand is geen geldig certificaat"
                }
                commitAddNotification(context, { color: 'error', content: `eHealth fout: ${error_message}` });
            }

        }
    },
};

const { dispatch } = getStoreAccessors<MainState | any, State>('');

export const dispatchCheckApiError = dispatch(actions.actionCheckApiError);
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn);
export const dispatchGetUserProfile = dispatch(actions.actionGetUserProfile);
export const dispatchGetUserProfileWithGoogleValidation = dispatch(actions.actionGetUserProfileWithGoogleValidation);
export const dispatchGetPhysiotherapists = dispatch(actions.actionGetPhysiotherapists);
export const dispatchGetPlanningConfiguration = dispatch(actions.actionGetPlanningConfiguration);
export const dispatchUpdatePlanningConfiguration = dispatch(actions.actionUpdatePlanningConfiguration);
export const dispatchLogIn = dispatch(actions.actionLogIn);
export const dispatchLogOut = dispatch(actions.actionLogOut);
export const dispatchUserLogOut = dispatch(actions.actionUserLogOut);
export const dispatchRemoveLogIn = dispatch(actions.actionRemoveLogIn);
export const dispatchRouteLoggedIn = dispatch(actions.actionRouteLoggedIn);
export const dispatchRouteLogOut = dispatch(actions.actionRouteLogOut);
export const dispatchUpdateUserProfile = dispatch(actions.actionUpdateUserProfile);
export const dispatchRemoveNotification = dispatch(actions.removeNotification);
export const dispatchPasswordRecovery = dispatch(actions.passwordRecovery);
export const dispatchResetPassword = dispatch(actions.resetPassword);
export const dispatchUpdateCalendar = dispatch(actions.actionUpdateGoogleCalendar);
export const dispatchGoogleAuthorizationToken = dispatch(actions.actionGoogleAuthorizationToken);
export const dispatchRegisterEHealth = dispatch(actions.actionRegisterEHealth);
export const dispatchLogInOnBehalfOf = dispatch(actions.actionLogInOnBehalfOf);
export const dispatchGetGoogleCalendars = dispatch(actions.actionGetGoogleCalendars)