import { NomenclatureState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    allNomenclatures: (state: NomenclatureState) => state.nomenclatures,
    nonConvHonoraria: (state: NomenclatureState) => state.non_conv_honoraria,
    oneNomenclature: (state: NomenclatureState) => (nomenclatureId: number) => {
        const filteredNomenclatures = state.nomenclatures.filter((nomenclature) => nomenclature.id === nomenclatureId);
        if (filteredNomenclatures.length > 0) {
            return { ...filteredNomenclatures[0] };
        }
    },
};

const { read } = getStoreAccessors<NomenclatureState, State>('');

export const readNomenclature = read(getters.oneNomenclature);
export const readNomenclatures = read(getters.allNomenclatures);
export const readNonConvHonoraria = read(getters.nonConvHonoraria);
