import { IAppointment, IFinancial } from '@/interfaces';
import { FinancialState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setFinancials(state: FinancialState, payload: IFinancial[]) {
        state.financials = payload;
    },
    setUnpaidAppointments(state: FinancialState, payload: IAppointment[]) {
        state.unpaidAppointments = payload;
    },
    setUnattestedAppointments(state: FinancialState, payload: IAppointment[]) {
        state.unattestedAppointments = payload;
    },
};

const { commit } = getStoreAccessors<FinancialState, State>('');

export const commitSetFinancials = commit(mutations.setFinancials);
export const commitSetUnpaidAppointments = commit(mutations.setUnpaidAppointments);
export const commitSetUnattestedAppointments = commit(mutations.setUnattestedAppointments);