import "core-js/stable";
// Import Component hooks before component definitions
import './component-hooks';
import Vue from 'vue';
import './plugins/vuetify';
import './plugins/vee-validate';
import './plugins/vue-gtag';
import App from './App.vue';
import router from './router';
import store from '@/store';
import './registerServiceWorker';
import 'vuetify/dist/vuetify.min.css';
import * as Sentry from '@sentry/vue'
import { isAxiosError } from "axios";

Vue.config.productionTip = false;

if ((process.env.VUE_APP_ENV === 'production') || (process.env.VUE_APP_ENV === 'staging')){
  // Disable Sentry during development
  Sentry.init({
    Vue: Vue,
    dsn: "https://eac05145381c45688e62d8f8b6bccbd6@o1280989.ingest.sentry.io/6485964",
    beforeSend: handleSentryBeforeSend
  });
}

function handleSentryBeforeSend(event, hint) {
  return addAxiosContextRecursive(event, hint?.originalException);
}

function addAxiosContextRecursive(event, error) {
  if (isAxiosError(error)) {
    return addAxiosContext(event, error);
  } else if (error instanceof Error && error.cause) {
    addAxiosContextRecursive(event, error.cause);
  }
}

function addAxiosContext(event, error) {
  if (error.response) {
    const contexts = { ...event.contexts };
    contexts.Axios = { Response: error.response };
    event.contexts = contexts;
  }
  return event
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
