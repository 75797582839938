import { api } from '@/api';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { AttestationState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetDocumentsForAttestation, commitClearAttestations, commitSetAttestations } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { IAttestationCreate, IAttestationWithAppointments } from './interfaces';

type MainContext = ActionContext<AttestationState, State>;

export const actions = {

    async actionGetAttestations(context: MainContext) {
        try {
            commitClearAttestations(context)
            let skip = 0;
            let limit = 50
            let results: IAttestationWithAppointments[] = []
            while (true){
                const response = await api.getAttestations(context.rootState.main.token, skip=skip, limit=limit);
                if (response) {
                    if (response.data.length == 0){
                        break
                    }
                    results.push(...response.data)
                    await commitSetAttestations(context, results)
                    skip += limit;
                }
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetAppointmentsForPatientWithoutAttestation(context: MainContext, payload: {patientId: number}) {
        try {
            const response = (await Promise.all([
                api.getAppointmentsForPatientWithoutAttestation(context.rootState.main.token, payload.patientId),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            return response.data
        } catch (error) {
            await dispatchCheckApiError(context, error);
            return []
        }
    },
    
    async actionCreateAttestation(context: MainContext, payload: IAttestationCreate) {
        try {
            const response = (await Promise.all([
                api.createAttestation(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            return response.data
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateAttestation(context: MainContext, payload: {attestationId: number, obj: IAttestationCreate}) {
        try {
            const response = (await Promise.all([
                api.updateAttestation(context.rootState.main.token, payload.attestationId, payload.obj),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            return response.data
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionDeleteAttestation(context: MainContext, payload: {attestationId: number}) {
        try {
            const response = (await Promise.all([
                api.deleteAttestation(context.rootState.main.token, payload.attestationId),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            return response.data
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetAttestation(context: MainContext, attestationId: number) {
        try {
            const response = (await Promise.all([
                api.getAttestation(context.rootState.main.token, attestationId),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            return response.data
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionLinkAppointmentToAttestation(context: MainContext, payload: {attestationId: number, appointmentId: number}) {
        try {
            const response = (await Promise.all([
                api.linkAppointmentToAttestation(context.rootState.main.token, payload.attestationId, payload.appointmentId),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            return response.data
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUnlinkAppointmentToAttestation(context: MainContext, payload: {attestationId: number, appointmentId: number}) {
        try {
            const response = (await Promise.all([
                api.unlinkAppointmentToAttestation(context.rootState.main.token, payload.attestationId, payload.appointmentId),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            return response.data
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetAppointmentsWithoutAttestation(context: MainContext, patientId: number) {
        try {
            const response = (await Promise.all([
                api.getAppointmentsForPatientWithoutAttestation(context.rootState.main.token, patientId),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            return response.data
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    //Documents
    async addDocument(context: MainContext, payload: { attestationId: number, file: File }) {
        try {
            const response = await api.addDocumentToAttestation(context.rootState.main.token, payload.attestationId, payload.file);
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async getDocuments(context: MainContext, payload: { attestationId: number }) {
        if (payload.attestationId === undefined){
            return
        }
        try {
            const response = await api.getDocumentsForAttestation(context.rootState.main.token, payload.attestationId);
            if (response) {
                commitSetDocumentsForAttestation(context, {attestationId: payload.attestationId, docs: response.data});
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
};

const { dispatch } = getStoreAccessors<AttestationState, State>('');

export const dispatchGetAttestations = dispatch(actions.actionGetAttestations);
export const dispatchCreateAttestation = dispatch(actions.actionCreateAttestation);
export const dispatchUpdateAttestation = dispatch(actions.actionUpdateAttestation);
export const dispatchDeleteAttestation = dispatch(actions.actionDeleteAttestation);
export const dispatchGetAttestation = dispatch(actions.actionGetAttestation);
export const dispatchLinkAppointmentToAttestation = dispatch(actions.actionLinkAppointmentToAttestation);
export const dispatchUnlinkAppointmentToAttestation = dispatch(actions.actionUnlinkAppointmentToAttestation);
export const dispatchGetAppointmentsForPatientWithoutAttestation = dispatch(actions.actionGetAppointmentsForPatientWithoutAttestation);
export const dispatchGetAppointmentsWithoutAttestation = dispatch(actions.actionGetAppointmentsWithoutAttestation);

export const dispatchAddDocumentToAttestation = dispatch(actions.addDocument);
export const dispatchGetDocumentsForAttestation = dispatch(actions.getDocuments);