import { api } from '@/api';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { DocumentState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetThirdPartyPayment} from './mutations';
import { commitAddNotification } from '../main/mutations';
import { IThirdPartyPaymentCreate } from '@/interfaces';

type MainContext = ActionContext<DocumentState, State>;

export const actions = {
    async actionGetThirdPartyPayment(context: MainContext, payload: IThirdPartyPaymentCreate) {
        try {
            const response = await api.createThirdPartyPayment(context.rootState.main.token, payload);
            console.log(response.headers);
            if (response) {
                const type = response.headers['content-type'];
                const blob = new Blob([response.data], { type});
                commitSetThirdPartyPayment(context, blob);
            }
        } catch (error: any) {
            console.log("error in actionGetThirdPartyPayment")
            console.log(error.response)
            console.log(error.response.data)
            let jsonMessage = JSON.parse(await error.response.data.text())
            if (error.response && error.response.status == 422){
                commitAddNotification(context, { color: 'error', content: jsonMessage.detail[0]["msg"] });
            }
            else if (error.response){
                commitAddNotification(context, { color: 'error', content: jsonMessage.detail });
            }
        }
    },
};

const { dispatch } = getStoreAccessors<DocumentState, State>('');

export const dispatchGetThirdPartyPayment = dispatch(actions.actionGetThirdPartyPayment);
