import { PrescriptionState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    Prescriptions: (state: PrescriptionState) => state.prescriptions,
    ActivePrescriptions: (state: PrescriptionState) => {
        return state.prescriptions.filter((prescription) => prescription.disabled === false);
    },
    OnePrescription: (state: PrescriptionState) => (prescriptionId: number) => {
        const filteredPrescriptions = state.prescriptions.filter((prescription) => prescription.id === prescriptionId);
        if (filteredPrescriptions.length > 0) {
            return { ...filteredPrescriptions[0] };
        }
    },
};

const { read } = getStoreAccessors<PrescriptionState, State>('');

export const readOnePrescription = read(getters.OnePrescription);
export const readPrescriptions = read(getters.Prescriptions);
export const readActivePrescriptions = read(getters.ActivePrescriptions);
