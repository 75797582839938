import { IInvoice } from '@/interfaces';
import { InvoiceState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setInvoices(state: InvoiceState, payload: IInvoice[]) {
        state.invoices = payload;
    },
    removeInvoice(state: InvoiceState, payload: number) {
        state.invoices = state.invoices.filter((invoice: IInvoice) => invoice.id !== payload);
    },
};

const { commit } = getStoreAccessors<InvoiceState, State>('');

export const commitSetInvoices = commit(mutations.setInvoices);
export const commitRemoveInvoice = commit(mutations.removeInvoice);

