import Vue from 'vue';
import Router from 'vue-router';

import RouterComponent from './components/RouterComponent.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "start" */ './views/main/Start.vue'),
      children: [
        {
          path: 'home',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "home" */ './views/Home.vue'),
        },
        {
          path: 'login',
          component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
        },
        {
          path: 'privacy',
          component: () => import(/* webpackChunkName: "privacy" */ './views/Privacy.vue'),
        },
        {
          path: 'pricing',
          component: () => import(/* webpackChunkName: "pricing" */ './views/Pricing.vue'),
        },
        {
          path: 'recover-password',
          component: () => import(/* webpackChunkName: "recover-password" */ './views/PasswordRecovery.vue'),
        },
        {
          path: 'reset-password',
          component: () => import(/* webpackChunkName: "reset-password" */ './views/ResetPassword.vue'),
        },
        {
          path: 'main',
          component: () => import(/* webpackChunkName: "main" */ './views/main/Main.vue'),
          children: [
            {
              path: 'profile',
              component: RouterComponent,
              redirect: 'profile/view',
              children: [
                {
                  path: 'view',
                  component: () => import(
                    /* webpackChunkName: "main-profile" */ './views/main/profile/UserProfile.vue'),
                }
              ],
            },
            // nomenclaturen
            {
              path: 'nomenclatures',
              component: () => import(/* webpackChunkName: "main-nomenclatures" */ './views/main/Nomenclatures.vue'),
            },
            {
              path: 'nonconv/nomenclatures',
              component: () => import(/* webpackChunkName: "main-nomenclatures" */ './views/main/nonconv/Nomenclatures.vue'),
            },
            // patienten
            {
              path: 'patients/create',
              component: () => import(/* webpackChunkName: "main-nomenclatures" */ './views/main/patients/CreatePatient.vue'),
            },
            {
              path: 'patients/all',
              component: () => import(/* webpackChunkName: "main-nomenclatures" */ './views/main/patients/Patients.vue'),
            },
            {
              path: 'patients/edit/:id',
              name: 'main-patients-edit',
              component: () => import(
                /* webpackChunkName: "main-admin-users-edit" */ './views/main/patients/EditPatient.vue'),
            },
            // voorschriften
            {
              path: 'prescriptions/create',
              component: () => import(/* webpackChunkName: "main-nomenclatures" */ './views/main/prescriptions/CreatePrescription.vue'),
            },
            {
              path: 'prescriptions/all',
              component: () => import(/* webpackChunkName: "main-nomenclatures" */ './views/main/prescriptions/Prescriptions.vue'),
            },
            {
              path: 'prescriptions/edit/:id',
              name: 'main-prescriptions-edit',
              component: () => import(
                /* webpackChunkName: "main-admin-users-edit" */ './views/main/prescriptions/EditPrescription.vue'),
            },
            // getuigschriften
            {
              path: 'attestations/create',
              component: () => import(/* webpackChunkName: "main-nomenclatures" */ './views/main/attestations/CreateAttestation.vue'),
            },
            {
              path: 'attestations/all',
              component: () => import(/* webpackChunkName: "main-attestations" */ './views/main/attestations/Attestations.vue'),
            },
            {
              path: 'attestations/detail/:id',
              name: 'main-attestations-detail',
              component: () => import(
                /* webpackChunkName: "main-admin-users-edit" */ './views/main/attestations/DetailAttestation.vue'),
            },
            // efact
            {
              path: 'efact/create',
              component: () => import(/* webpackChunkName: "main-efact" */ './views/main/efact/CreateEFact.vue'),
            },
            {
              path: 'efact/all',
              component: () => import(/* webpackChunkName: "main-efact" */ './views/main/efact/EFact.vue'),
            },
            // invoices
            {
              path: 'invoices/all',
              component: () => import(/* webpackChunkName: "main-invoices" */ './views/main/invoices/Invoices.vue'),
            },
            // financials
            {
              path: 'financials/all',
              component: () => import(/* webpackChunkName: "main-financials" */ './views/main/financials/Financials.vue'),
            },
            // planning
            {
              path: 'planning/calendar',
              component: () => import(/* webpackChunkName: "main-planning-calendar" */ './views/main/planning/Calendar.vue'),
            },
            {
              path: 'planning/unconfirmed',
              component: () => import(/* webpackChunkName: "main-planning-unconfirmed" */ './views/main/planning/Unconfirmed.vue'),
            },
            {
              path: 'planning/configure',
              component: () => import(/* webpackChunkName: "main-planning-configure" */ './views/main/planning/ConfigurePlanner.vue'),
            },
            {
              path: 'planning/table',
              component: () => import(/* webpackChunkName: "main-planning-table" */ './views/main/planning/Table.vue'),
            },
            {
              path: 'planning/unsynced',
              component: () => import(/* webpackChunkName: "main-planning-calendar" */ './views/main/planning/Unsynced.vue'),
            },
            {
              path: 'planning/unsynced/edit',
              name: 'main-unsynced-edit',
              component: () => import(/* webpackChunkName: "main-planning-calendar" */ './views/main/planning/EditUnsynced.vue'),
            },
            // documents
            {
              path: 'documents/third-party-payment',
              component: () => import(/* webpackChunkName: "main-documents-third-party-payment" */ './views/main/documents/ThirdPartyPayment.vue'),
            },
            // admin
            {
              path: 'admin',
              component: () => import(/* webpackChunkName: "main-admin" */ './views/main/admin/Admin.vue'),
              redirect: 'admin/users/all',
              children: [
                {
                  path: 'users',
                  redirect: 'users/all',
                },
                {
                  path: 'users/all',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users" */ './views/main/admin/AdminUsers.vue'),
                },
                {
                  path: 'users/edit/:id',
                  name: 'main-admin-users-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-edit" */ './views/main/admin/EditUser.vue'),
                },
                {
                  path: 'users/create',
                  name: 'main-admin-users-create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-create" */ './views/main/admin/CreateUser.vue'),
                },
                {
                  path: 'physiotherapy/all',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users" */ './views/main/admin/AdminPhysiotherapy.vue'),
                },
                {
                  path: 'on_behalf_of',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users" */ './views/main/admin/LoginOnBehalf.vue'),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/*', redirect: '/',
    },
  ],
});
