import { AttestationState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    Attestations: (state: AttestationState) => state.attestations,
    OneAttestation: (state: AttestationState) => (attestationId: number) => {
        const filtered = state.attestations.filter((a) => a.id === attestationId);
        if (filtered.length > 0) {
            return { ...filtered[0] };
        }
    },
};

const { read } = getStoreAccessors<AttestationState, State>('');

export const readOneAttestation = read(getters.OneAttestation);
export const readAttestations = read(getters.Attestations);