import { AppointmentState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    Appointments: (state: AppointmentState) => state.appointments,
    OneAppointment: (state: AppointmentState) => (appointmentId: number) => {
        const filteredAppointments = state.appointments.filter((appointment) => appointment.id === appointmentId);
        if (filteredAppointments.length > 0) {
            return { ...filteredAppointments[0] };
        }
    },
    UnconfirmedAppointments: (state: AppointmentState) => state.unconfirmed_appointments,
    OneUnconfirmedAppointment: (state: AppointmentState) => (appointmentId: number) => {
        const filteredAppointments = state.unconfirmed_appointments.filter((appointment) => appointment.id === appointmentId);
        if (filteredAppointments.length > 0) {
            return { ...filteredAppointments[0] };
        }
    },
    UnsyncedEvents: (state: AppointmentState) => state.unsynced_events,
    Table: (state: AppointmentState) => state.table,
    SplitProposals: (state: AppointmentState) => state.split_proposals
};

const { read } = getStoreAccessors<AppointmentState, State>('');

export const readOneAppointment = read(getters.OneAppointment);
export const readAppointments = read(getters.Appointments);
export const readOneUnconfirmedAppointment = read(getters.OneUnconfirmedAppointment);
export const readUnconfirmedAppointments = read(getters.UnconfirmedAppointments);
export const readUnsyncedEvents = read(getters.UnsyncedEvents);
export const readTable = read(getters.Table);
export const readSplitProposals = read(getters.SplitProposals)