import axios from 'axios';
import { apiUrl } from '@/env';
import {
  IUserAppointmentStats,
  IUserProfile,
  IMFAProvisioningUrl,
  IUserProfileUpdate,
  IUserProfileCreate,
  IFinancial,
  INomenclature,
  IPatientCreate,
  IPatientUpdate,
  IPatient,
  IPrescription,
  IPrescriptionUpdate,
  IPrescriptionCreate,
  IAppointment,
  IAppointmentCreate,
  IAppointmentUpdate,
  IGoogleEvent,
  IGoogleEventUpdate,
  ITable,
  IThirdPartyPaymentCreate,
  ISplitProposal,
  IPhysiotherapy,
  INonConventionalHonorarium,
  INonConventionalHonorariumUpdate,
  INonConventionalHonorariumCreate,
  IPatientComment,
  IPatientCommentCreate,
  IPatientCommentUpdate,
  IDocument,
  IUnconfirmedAppointment,
  IPlanningConfiguration,
  IAppointmentPaymentSubmit,
  IInvoiceCreate,
  IInvoice,
  IAppointmentHonorarium,
  IInstitution,
  IRequisition,
  IBankAccount,
  IDocumentSize,
  IContactModel,
  IMoveOwner
} from './interfaces';
import {
  IAttestation, IAttestationCreate, IAttestationWithAppointments
} from './store/attestations/interfaces';
import {
  IEAgreement,
  IPrescriptionInput, IAskAgreementInput, ICancelAgreementInput
} from './interfaces/eagreement';
import {
  ISendEAttestInput
} from './interfaces/eattest';
import {
  ISendEFactInput, IEfact, IEFactInputModelUpdate
} from './interfaces/efact';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const api = {
  async logInGetToken(username: string, password: string, otp: string | null) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);
    params.append('otp', otp ?? "");

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async getOTPRequired(email: string) {
    return axios.get(`${apiUrl}/api/v1/login/otp-required/${email}`);
  },
  async getTokenOnBehalfOf(token: string, onBehalfOf: string) {
    return axios.get(`${apiUrl}/api/v1/users/on_behalf_of/${onBehalfOf}`, authHeaders(token));
  },
  async getMe(token: string, checkGoogle: boolean = false) {
    if (checkGoogle){
      return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me?check_google=true`, authHeaders(token));
    } else {
      return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
    }
  },
  async getMfaProvisioningUrl(token: string) {
    return axios.get<IMFAProvisioningUrl>(`${apiUrl}/api/v1/users/me/mfa`, authHeaders(token));
  },
  async getPhysiotherapyUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/me/physiotherapy`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(`${apiUrl}/api/v1/users/me`, data, authHeaders(token));
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  },
  async getUserAppointmentStats(token: string) {
    return axios.get<IUserAppointmentStats[]>(`${apiUrl}/api/v1/users/appointment_stats`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
  // Google
  async getCalendars(token: string) {
    return axios.get<string[]>(`${apiUrl}/api/v1/google/calendar`, authHeaders(token));
  },
  async putCalendar(token: string, calendar_id: string) {
    return axios.patch(`${apiUrl}/api/v1/google/`, {calendar_id}, authHeaders(token));
  },
  async syncEvents(token: string, performSync: boolean, start_date: string | null, end_date: string | null) {
    let url = `${apiUrl}/api/v1/google/appointments?perform_sync=${performSync}`
    if (start_date){
      url += `&start_date=${start_date}`
    }
    if (end_date){
      url += `&end_date=${end_date}`
    }
    return axios.get<IGoogleEvent[]>(url, authHeaders(token));
  },
  async updateEvent(token: string, eventId: number, data: IGoogleEventUpdate) {
    return axios.patch(`${apiUrl}/api/v1/google/${eventId}/ignore`, data, authHeaders(token));
  },
  async setGoogleAuthorizationToken(token: string, code: string) {
    return axios.post(`${apiUrl}/api/v1/google/token`, {code}, authHeaders(token));
  },

  // Nomenclatures
  async getNomenclatures(token: string) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params:  {limit: 500},
    };
    return axios.get<INomenclature[]>(`${apiUrl}/api/v1/nomenclatures/current_honorarium/`, config);
  },
  async getNonConventionalHonoraria(token: string) {
    return axios.get<INonConventionalHonorarium[]>(`${apiUrl}/api/v1/non_conventional_honorarium/`, authHeaders(token));
  },
  async updateNonConventionalHonorarium(token: string, nonConventionalHonorariumId: number, data: INonConventionalHonorariumUpdate) {
    return axios.put(`${apiUrl}/api/v1/non_conventional_honorarium/${nonConventionalHonorariumId}`, data, authHeaders(token));
  },
  async deleteNonConventionalHonorarium(token: string, nonConventionalHonorariumId: number) {
    return axios.delete(`${apiUrl}/api/v1/non_conventional_honorarium/${nonConventionalHonorariumId}`, authHeaders(token));
  },
  async createNonConventionalHonorarium(token: string, data: INonConventionalHonorariumCreate) {
    return axios.post(`${apiUrl}/api/v1/non_conventional_honorarium/`, data, authHeaders(token));
  },
  // Patients
  async getPatients(token: string, skip: number = 0, limit: number = 100) {
    return axios.get<IPatient[]>(`${apiUrl}/api/v1/patients/?exclude_disabled=false&skip=${skip}&limit=${limit}`, authHeaders(token));
  },
  async updatePatient(token: string, patientId: number, data: IPatientUpdate) {
    return axios.put(`${apiUrl}/api/v1/patients/${patientId}`, data, authHeaders(token));
  },
  async disablePatient(token: string, patientId: number) {
    // doesn't actually delete, just disable + hides it.
    return axios.delete(`${apiUrl}/api/v1/patients/${patientId}`, authHeaders(token));
  },
  async enablePatient(token: string, patientId: number) {
    // re-activate.
    return axios.get(`${apiUrl}/api/v1/patients/${patientId}/enable`, authHeaders(token));
  },
  async createPatient(token: string, data: IPatientCreate) {
    return axios.post(`${apiUrl}/api/v1/patients/`, data, authHeaders(token));
  },

  async countEPathology(token: string, patientId: number) {
    return axios.get<number>(`${apiUrl}/api/v1/patients/${patientId}/epathology/count`, authHeaders(token));
  },
  async countFaPathology(token: string, patientId: number) {
    return axios.get<number>(`${apiUrl}/api/v1/patients/${patientId}/fapathology/count`, authHeaders(token));
  },
  async countFbPathology(token: string, patientId: number) {
    return axios.get<number>(`${apiUrl}/api/v1/patients/${patientId}/fbpathology/count`, authHeaders(token));
  },

  async addDocumentToPatient(token: string, patientId: number, file: File) {
    let headers = authHeaders(token)
    const formData = new FormData();
    formData.append('file', file)
    headers["Content-Type"] = "multipart/form-data"
    return axios.post(`${apiUrl}/api/v1/patients/${patientId}/document`, formData, authHeaders(token));
  },
  async getDocumentsForPatient(token: string, patientId: number) {
    return axios.get<IDocument[]>(`${apiUrl}/api/v1/patients/${patientId}/documents`, authHeaders(token));
  },

  // Patient comments
  async getPatientComments(token: string, patientId?: number) {
    return axios.get<IPatientComment[]>(`${apiUrl}/api/v1/patient_comments/?patient_id=${patientId}`, authHeaders(token));
  },
  async updatePatientComment(token: string, patientId: number, data: IPatientCommentUpdate) {
    return axios.put(`${apiUrl}/api/v1/patient_comments/${patientId}`, data, authHeaders(token));
  },
  async deletePatientComment(token: string, patientId: number) {
    return axios.delete(`${apiUrl}/api/v1/patient_comments/${patientId}`, authHeaders(token));
  },
  async createPatientComment(token: string, data: IPatientCommentCreate) {
    return axios.post(`${apiUrl}/api/v1/patient_comments/`, data, authHeaders(token));
  },
  // Prescriptions
  async getPrescriptions(token: string, skip: number = 0, limit: number = 100) {
    return axios.get<IPrescription[]>(`${apiUrl}/api/v1/prescriptions/?skip=${skip}&limit=${limit}`, authHeaders(token));
  },
  async updatePrescription(token: string, patientId: number, data: IPrescriptionUpdate) {
    return axios.put(`${apiUrl}/api/v1/prescriptions/${patientId}`, data, authHeaders(token));
  },
  async deletePrescription(token: string, patientId: number) {
    return axios.delete(`${apiUrl}/api/v1/prescriptions/${patientId}`, authHeaders(token));
  },
  async createPrescription(token: string, data: IPrescriptionCreate) {
    return axios.post(`${apiUrl}/api/v1/prescriptions/`, data, authHeaders(token));
  },
  async addDocumentToPrescription(token: string, prescriptionId: number, file: File) {
    let headers = authHeaders(token)
    const formData = new FormData();
    formData.append('file', file)
    headers["Content-Type"] = "multipart/form-data"
    return axios.post(`${apiUrl}/api/v1/prescriptions/${prescriptionId}/document`, formData, authHeaders(token));
  },
  async getDocumentsForPrescription(token: string, prescriptionId: number) {
    return axios.get<IDocument[]>(`${apiUrl}/api/v1/prescriptions/${prescriptionId}/documents`, authHeaders(token));
  },
  // Attestations
  async getAttestations(token: string, skip: number = 0, limit: number = 100) {
    return axios.get<IAttestationWithAppointments[]>(`${apiUrl}/api/v1/attestations/?skip=${skip}&limit=${limit}`, authHeaders(token));
  },
  async getAttestation(token: string, attestationId: number) {
    return axios.get<IAttestationWithAppointments>(`${apiUrl}/api/v1/attestations/${attestationId}`, authHeaders(token));
  },
  async createAttestation(token: string, data: IAttestationCreate) {
    return axios.post<IAttestationWithAppointments>(`${apiUrl}/api/v1/attestations/`, data, authHeaders(token));
  },
  async updateAttestation(token: string, attestationId: number, data: IAttestationCreate) {
    return axios.put<IAttestationWithAppointments>(`${apiUrl}/api/v1/attestations/${attestationId}`, data, authHeaders(token));
  },
  async deleteAttestation(token: string, attestationId: number) {
    return axios.delete(`${apiUrl}/api/v1/attestations/${attestationId}`, authHeaders(token));
  },
  async linkAppointmentToAttestation(token: string, attestationId: number, appointmentId: number) {
    return axios.post<IAttestationWithAppointments>(`${apiUrl}/api/v1/attestations/${attestationId}/appointment/${appointmentId}`, {}, authHeaders(token));
  },
  async unlinkAppointmentToAttestation(token: string, attestationId: number, appointmentId: number) {
    return axios.delete<IAttestationWithAppointments>(`${apiUrl}/api/v1/attestations/${attestationId}/appointment/${appointmentId}`, authHeaders(token));
  },
  async addDocumentToAttestation(token: string, attestationId: number, file: File) {
    let headers = authHeaders(token)
    const formData = new FormData();
    formData.append('file', file)
    headers["Content-Type"] = "multipart/form-data"
    return axios.post(`${apiUrl}/api/v1/attestations/${attestationId}/document`, formData, authHeaders(token));
  },
  async getDocumentsForAttestation(token: string, attestationId: number) {
    return axios.get<IDocument[]>(`${apiUrl}/api/v1/attestations/${attestationId}/documents`, authHeaders(token));
  },
  async emailAttest(token: string, id: number) {
    return axios.post(`${apiUrl}/api/v1/attestations/${id}/send`, {}, authHeaders(token));
  },
  // Appointments
  async getAppointments(token: string, start: string | null, end: string | null, patient_id: number | null) {
    if (start)
    {
      let patientFilter = "";
      if (patient_id){
        patientFilter = `&patient_id=${patient_id}`
      }
      return axios.get<IAppointment[]>(`${apiUrl}/api/v1/appointments/?start=${start}&end=${end}${patientFilter}`, authHeaders(token));
    }
    else if (patient_id)
    {
      return axios.get<IAppointment[]>(`${apiUrl}/api/v1/appointments/?patient_id=${patient_id}`, authHeaders(token));
    }
    else {
      return axios.get<IAppointment[]>(`${apiUrl}/api/v1/appointments/planner/`, authHeaders(token));
    }
  },
  async getAppointmentsWithSchriftelijkVerslag(token: string, patient_id: number) {
    return axios.get<IAppointment[]>(`${apiUrl}/api/v1/patients/${patient_id}/schriftelijk_verslag`, authHeaders(token));
  },
  async getAppointmentsWithIntake(token: string, patient_id: number) {
    return axios.get<IAppointment[]>(`${apiUrl}/api/v1/patients/${patient_id}/intake`, authHeaders(token));
  },
  async getAppointmentHonorarium(token: string, id: number) {
    return axios.get<IAppointmentHonorarium>(`${apiUrl}/api/v1/appointments/${id}/honorarium`, authHeaders(token));
  },
  async getAppointmentsForPatientWithoutAttestation(token: string, patient_id: number) {
    return axios.get<IAppointment[]>(`${apiUrl}/api/v1/appointments/?patient_id=${patient_id}&without_attestation=true`, authHeaders(token));
  },
  async getUnconfirmedAppointments(token: string) {
    return axios.get<IUnconfirmedAppointment[]>(`${apiUrl}/api/v1/appointments/planner/`, authHeaders(token));
  },
  async getPlannerConfiguration(token: string) {
    return axios.get<IPlanningConfiguration>(`${apiUrl}/api/v1/appointments/planner/configuration/`, authHeaders(token));
  },
  async updatePlannerConfiguration(token: string, data: IPlanningConfiguration) {
    return axios.put(`${apiUrl}/api/v1/appointments/planner/configuration/`, data, authHeaders(token));
  },
  async checkConflictUnconfirmed(token: string, appointment_id: number) {
    return axios.get<boolean>(`${apiUrl}/api/v1/appointments/planner/${appointment_id}`, authHeaders(token));
  },
  async confirmUnconfirmedAppointment(token: string, appointment_id: number, patient_id: number) {
    return axios.post(`${apiUrl}/api/v1/appointments/planner/${appointment_id}/${patient_id}`, {}, authHeaders(token));
  },
  async deleteUnconfirmedAppointment(token: string, appointment_id: number){
    return axios.delete(`${apiUrl}/api/v1/appointments/planner/${appointment_id}`, authHeaders(token));
  },
  async getAppointmentTable(token: string, start: string, end: string) {
    return axios.get<ITable[]>(`${apiUrl}/api/v1/appointments/table?start=${start}&end=${end}`, authHeaders(token));
  },
  async updateAppointment(token: string, patientId: number, data: IAppointmentUpdate) {
    return axios.put(`${apiUrl}/api/v1/appointments/${patientId}`, data, authHeaders(token));
  },
  async deleteAppointment(token: string, patientId: number) {
    return axios.delete(`${apiUrl}/api/v1/appointments/${patientId}`, authHeaders(token));
  },
  async createAppointment(token: string, data: IAppointmentCreate) {
    return axios.post(`${apiUrl}/api/v1/appointments/`, data, authHeaders(token));
  },
  async moveAppointment(token: string, id: number, data: IMoveOwner) {
    return axios.post<IAppointment>(`${apiUrl}/api/v1/appointments/${id}/move`, data, authHeaders(token));
  },
  async getSplitProposal(token: string, id: number) {
    return axios.get<ISplitProposal[]>(`${apiUrl}/api/v1/appointments/${id}/split`, authHeaders(token));
  },
  async submitSplitProposal(token: string, id: number, data: ISplitProposal) {
    return axios.post(`${apiUrl}/api/v1/appointments/${id}/split`, data, authHeaders(token));
  },
  async handlePaymentDialog(token: string, data: IAppointmentPaymentSubmit) {
    return axios.post(`${apiUrl}/api/v1/appointments/payments`, data, authHeaders(token));
  },
  // Documents
  async createThirdPartyPayment(token: string, data: IThirdPartyPaymentCreate) {
    return axios.post<Blob>(`${apiUrl}/api/v1/documents/third_party_payment`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    });
  },
  async deleteDocument(token: string, documentId: number) {
    return axios.delete(`${apiUrl}/api/v1/documents/${documentId}`, authHeaders(token));
  },
  async downloadDocument(token: string, documentId: number) {
    return axios.get<Blob>(`${apiUrl}/api/v1/documents/${documentId}/content`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      });
  },
  async getDocumentSize(token: string, documentId: string) {
    return axios.get<IDocumentSize>(`${apiUrl}/api/v1/documents/${documentId}/size`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  },

  //Physiotherapy
  async getPhysiotherapies(token: string) {
    return axios.get<IPhysiotherapy[]>(`${apiUrl}/api/v1/physiotherapy/`, authHeaders(token));
  },
  async deletePhysiotherapy(token: string, physioId: number) {
    return axios.delete(`${apiUrl}/api/v1/physiotherapy/${physioId}`, authHeaders(token));
  },
  async createPhysiotherapy(token: string, data: IPhysiotherapy) {
    return axios.post(`${apiUrl}/api/v1/physiotherapy/`, data, authHeaders(token));
  },
  async linkPhysiotherapyToUser(token: string, userId: number, physioId: number) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}/physiotherapy/${physioId}`, {}, authHeaders(token));
  },

  //eHealth
  async registerEHealth(token: string, ssin: string, passphrase: string, certificate: File) {
    let headers = authHeaders(token)
    const formData = new FormData();
    formData.append('ssin', ssin)
    formData.append('passphrase', passphrase)
    formData.append('certificate', certificate)
    headers["Content-Type"] = "multipart/form-data"
    return axios.post(`${apiUrl}/api/v1/ehealth/`, formData, authHeaders(token));
  },
  async queryMemberData(token: string, ssin: string|null, io: string|null, ioMembership: string|null, notbefore: string, notOnOrAfter: string, hospitalized: boolean, invoicing: boolean) {
    if (ssin !== null){
        return axios.get(`${apiUrl}/api/v1/ehealth/mda?ssin=${ssin}&notBefore=${notbefore}&notOnOrAfter=${notOnOrAfter}&hospitalized=${hospitalized}&invoicing=${invoicing}`, authHeaders(token));
    } else {
      return axios.get(`${apiUrl}/api/v1/ehealth/mda?io=${io}&io_membership=${ioMembership}&notBefore=${notbefore}&notOnOrAfter=${notOnOrAfter}&hospitalized=${hospitalized}&invoicing=${invoicing}`, authHeaders(token));

    }
  },
  async consultEAgreement(token: string, patientId: number, useInsurancymembership: boolean) {
    return axios.get<IEAgreement[]>(`${apiUrl}/api/v1/ehealth/eagreement?patient_id=${patientId}&use_insurancy_membership=${useInsurancymembership}`, authHeaders(token));
  },
  async getAgreements(token: string, patientId: number) {
    return axios.get<IEAgreement[]>(`${apiUrl}/api/v1/eagreements/${patientId}`, authHeaders(token));
  },
  async getEFacts(token: string) {
    return axios.get<IEfact[]>(`${apiUrl}/api/v1/ehealth/efacts`, authHeaders(token));
  },
  async getEFactMessages(token: string) {
    return axios.get(`${apiUrl}/api/v1/ehealth/efacts/messages`, authHeaders(token));
  },
  async asyncMessages(token: string, patientId: number) {
    return axios.get(`${apiUrl}/api/v1/ehealth/eagreement/messages/${patientId}`, authHeaders(token));
  },
  async askEAgreement(token: string, data: IAskAgreementInput) {
    return axios.post(`${apiUrl}/api/v1/ehealth/eagreement/ask`, data, authHeaders(token));
  },
  async cancelEAgreement(token: string, data: ICancelAgreementInput) {
    return axios.post(`${apiUrl}/api/v1/ehealth/eagreement/cancel`, data, authHeaders(token));
  },
  async getAddressBook(token: string, nihii: string) {
    return axios.get(`${apiUrl}/api/v1/ehealth/addressbook/${nihii}`, authHeaders(token));
  },
  async searchAddressBook(token: string, last_name_search: string) {
    return axios.get(`${apiUrl}/api/v1/ehealth/addressbook/search/${last_name_search}`, authHeaders(token));
  },
  async sendEAttest(token: string, data: ISendEAttestInput[]) {
    return axios.post(`${apiUrl}/api/v1/ehealth/eattest`, data, authHeaders(token));
  },
  async sendEFact(token: string, data: ISendEFactInput) {
    return axios.post(`${apiUrl}/api/v1/ehealth/efact`, data, authHeaders(token));
  },
  async updateEFact(token: string, id: number, data: IEFactInputModelUpdate) {
    return axios.put(`${apiUrl}/api/v1/ehealth/efact/${id}`, data, authHeaders(token));
  },
  async cancelEttest(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/ehealth/eattest/${id}`, authHeaders(token));
  },
  // invoices
  async createInvoice(token: string, data: IInvoiceCreate) {
    return axios.post(`${apiUrl}/api/v1/invoice/`, data, authHeaders(token));
  },
  async deleteInvoice(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/invoice/${id}`, 
    authHeaders(token));
  },
  async emailInvoice(token: string, id: number) {
    return axios.post(`${apiUrl}/api/v1/invoice/${id}/send`, {}, authHeaders(token));
  },
  async invoicePaid(token: string, id: number) {
    return axios.post(`${apiUrl}/api/v1/invoice/${id}/paid`, {}, authHeaders(token));
  },
  async getInvoices(token: string) {
    return axios.get<IInvoice[]>(`${apiUrl}/api/v1/invoice/`, authHeaders(token));
  },
  //financials
  async getFinancials(token: string) {
    return axios.get<IFinancial[]>(`${apiUrl}/api/v1/financials/`, authHeaders(token));
  },
  async getUnpaidAppointments(token: string) {
    return axios.get<IAppointment[]>(`${apiUrl}/api/v1/financials/unpaid_appointments`, authHeaders(token));
  },
  async getUnattestedAppointments(token: string) {
    return axios.get<IAppointment[]>(`${apiUrl}/api/v1/financials/unattested_appointments`, authHeaders(token));
  },
  //bank account data
  async getInstitutions(token: string) {
    return axios.get<IInstitution[]>(`${apiUrl}/api/v1/bank_account_data/institutions`, authHeaders(token));
  },
  async getAccounts(token: string) {
    return axios.get<IBankAccount[]>(`${apiUrl}/api/v1/bank_account_data/accounts`, authHeaders(token));
  },
  async checkTransactions(token: string) {
    return axios.get(`${apiUrl}/api/v1/bank_account_data/account/transactions`, authHeaders(token));
  },
  async postRequisition(token: string, institutionId: string) {
    let redirect = window.location.href + "?goCardless=phase2";
    return axios.post<IRequisition>(`${apiUrl}/api/v1/bank_account_data/institutions`, 
    {
      insitution_id: institutionId,
      redirect_url: redirect
    }, 
    authHeaders(token));
  },
  async postAccount(token: string, accountId: string) {
    return axios.post<IRequisition>(`${apiUrl}/api/v1/bank_account_data/accounts/${accountId}`, 
    {}, 
    authHeaders(token));
  },
  async deleteAccount(token: string) {
    return axios.delete(`${apiUrl}/api/v1/bank_account_data/accounts`, 
    authHeaders(token));
  },
  async sendContactEmail(token: string, data: IContactModel){
    return axios.post(`${apiUrl}/api/v1/utils/contact-email/`, data, authHeaders(token));
  }
};
