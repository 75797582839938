import { DocumentState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    thirdPartyPayment: (state: DocumentState) => state.third_party_payment,
};

const { read } = getStoreAccessors<DocumentState, State>('');

export const readThirdPartyPayment = read(getters.thirdPartyPayment);
