import Vue from 'vue';
import VeeValidate from 'vee-validate';

Vue.use(VeeValidate, {
    locale: 'nl',
    dictionary: {
      nl: {         messages: {
        required: () => 'Verplicht veld',
      } },
    },
  });
