import { InvoiceState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    Invoices: (state: InvoiceState) => state.invoices,
};

const { read } = getStoreAccessors<InvoiceState, State>('');

export const readInvoices = read(getters.Invoices);
