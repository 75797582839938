import { IAppointment, IGoogleEvent, ITable, ISplitProposalV2, IUnconfirmedAppointment } from '@/interfaces';
import { AppointmentState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setAppointments(state: AppointmentState, payload: IAppointment[]) {
        state.appointments = payload;
    },
    setUnConfirmedAppointments(state: AppointmentState, payload: IUnconfirmedAppointment[]) {
        state.unconfirmed_appointments = payload;
    },
    setUnsyncedEvents(state: AppointmentState, payload: IGoogleEvent[]) {
        state.unsynced_events = payload;
    },
    setTable(state: AppointmentState, payload: ITable[]) {
        state.table = payload;
    },
    setSplitProposals(state: AppointmentState, payload: ISplitProposalV2[]) {
        state.split_proposals = payload;
    },
    setAppointment(state: AppointmentState, payload: IAppointment) {
        const appointments = state.appointments.filter((appointment: IAppointment) => appointment.id !== payload.id);
        appointments.push(payload);
        state.appointments = appointments;
    },
};

const { commit } = getStoreAccessors<AppointmentState, State>('');

export const commitSetAppointment = commit(mutations.setAppointment);
export const commitSetUnconfirmedAppointments = commit(mutations.setUnConfirmedAppointments);
export const commitSetAppointments = commit(mutations.setAppointments);
export const commitSetUnsyncedEvents = commit(mutations.setUnsyncedEvents);
export const commitSetTable = commit(mutations.setTable);
export const commitSetSplitProposals = commit(mutations.setSplitProposals);
