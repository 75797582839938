import { api } from '@/api';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { FinancialState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification } from '../main/mutations';
import { commitSetFinancials, commitSetUnpaidAppointments, commitSetUnattestedAppointments} from './mutations';

type MainContext = ActionContext<FinancialState, State>;

export const actions = {
    async actionGetFinancials(context: MainContext) {
        try {
            const response = await api.getFinancials(context.rootState.main.token);
            if (response) {
                commitSetFinancials(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetUnpaidAppointments(context: MainContext) {
        try {
            const response = await api.getUnpaidAppointments(context.rootState.main.token);
            if (response) {
                commitSetUnpaidAppointments(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetUnattestedAppointments(context: MainContext) {
        try {
            const response = await api.getUnattestedAppointments(context.rootState.main.token);
            if (response) {
                commitSetUnattestedAppointments(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetInstitutions(context: MainContext) {
        try {
            const response = await api.getInstitutions(context.rootState.main.token);
            if (response) {
                return response.data;
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionPostRequisition(context: MainContext, institutionId: string) {
        try {
            const response = await api.postRequisition(context.rootState.main.token, institutionId);
            if (response) {
                return response.data;
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetAccounts(context: MainContext) {
        try {
            const response = await api.getAccounts(context.rootState.main.token);
            if (response) {
                return response.data;
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionPostAccount(context: MainContext, accountId: string) {
        try {
            const response = await api.postAccount(context.rootState.main.token, accountId);
            if (response) {
                return response.data;
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionDeleteAccount(context: MainContext) {
        try {
            const response = await api.deleteAccount(context.rootState.main.token);
            if (response) {
                return response.data;
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCheckTransactions(context: MainContext) {
        try {
            const response = await api.checkTransactions(context.rootState.main.token);
            if (response) {
                return response.data;
            }
        } catch (error: any) {
            if (error.response){
                let error_message = error.response.data.detail ?? "";
                if (error.response.status == 400 && error_message == "End User Agreement expired" ){
                    error_message = "Koppeling met bankrekening is verlopen.  Gelieve bankrekening opnieuw te koppelen onder profiel/integraties"
                    commitAddNotification(context, { color: 'error', content: error_message });
                } else {
                    await dispatchCheckApiError(context, error);
                }
            } else {
                await dispatchCheckApiError(context, error);
            }
        }
    },
};

const { dispatch } = getStoreAccessors<FinancialState, State>('');

export const dispatchGetFinancials = dispatch(actions.actionGetFinancials);
export const dispatchGetUnpaidAppointments = dispatch(actions.actionGetUnpaidAppointments);
export const dispatchGetUnattestedAppointments = dispatch(actions.actionGetUnattestedAppointments);
export const dispatchGetInstitutions = dispatch(actions.actionGetInstitutions);
export const dispatchPostRequisition = dispatch(actions.actionPostRequisition);
export const dispatchGetAccounts = dispatch(actions.actionGetAccounts);
export const dispatchPostAccount = dispatch(actions.actionPostAccount);
export const dispatchDeleteAccount = dispatch(actions.actionDeleteAccount);
export const dispatchCheckTransactions = dispatch(actions.actionCheckTransactions);