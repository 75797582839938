const env = process.env.VUE_APP_ENV;

let envApiUrl = '';
let envGapiKey = '';
let envGapiClientId = '';

if (env === 'production') {
  envApiUrl = `https://${process.env.VUE_APP_DOMAIN_PROD}`;
} else if (env === 'staging') {
  envApiUrl = `https://${process.env.VUE_APP_DOMAIN_STAG}`;
} else {
  envApiUrl = `http://${process.env.VUE_APP_DOMAIN_DEV}`;
}

if ((env === 'production') || (env === 'staging'))
{
  envGapiKey = 'AIzaSyAI8rd_eiGEZQO7eKa0WAzqkmb01027zTI'
  envGapiClientId = '1088215712282-itldv82d9ma58u1giq79avvpm5pj07pr.apps.googleusercontent.com'
} else {
  envGapiKey = 'AIzaSyDGb9YdxEO6NYVAUAE8OgGU0F2Qw9uMSaA'
  envGapiClientId = '608363180622-9s8614d27j04m1j5o5gibiuu9dg0ha5o.apps.googleusercontent.com'
}

export const apiUrl = envApiUrl;
export const gapiKey = envGapiKey;
export const gapiClientId = envGapiClientId;
export const appName = process.env.VUE_APP_NAME;
