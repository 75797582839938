import { FinancialState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    Financials: (state: FinancialState) => state.financials,
    UnpaidAppointments: (state: FinancialState) => state.unpaidAppointments,
    UnattestedAppointments: (state: FinancialState) => state.unattestedAppointments,
};

const { read } = getStoreAccessors<FinancialState, State>('');

export const readFinancials = read(getters.Financials);
export const readUnpaidAppointments = read(getters.UnpaidAppointments);
export const readUnattestedAppointments = read(getters.UnattestedAppointments);