import { IPatient, IPatientComment, IDocument } from '@/interfaces';
import { IMDA } from '@/interfaces/mda';
import { PatientState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    clearPatients(state: PatientState) {
        state.patients = []
    },
    setPatients(state: PatientState, payload: IPatient[]) {
        console.log('Received #', payload.length, 'patients')
        let payload_ids = payload.map((a) => a.id);
        state.patients = state.patients.filter((a) => !payload_ids.includes(a.id));
        state.patients.push(...payload);
        console.log('Total length now #', state.patients.length, 'patients')
    },
    setPatient(state: PatientState, payload: IPatient) {
        const patients = state.patients.filter((patient: IPatient) => patient.id !== payload.id);
        patients.push(payload);
        state.patients = patients;
    },
    setComments(state: PatientState, payload: IPatientComment[]) {
        state.patient_comments = payload;
    },
    setComment(state: PatientState, payload: IPatientComment) {
        const comments = state.patient_comments.filter((comment: IPatientComment) => comment.id !== payload.id);
        comments.push(payload);
        state.patient_comments = comments;
    },
    setPatientData(state: PatientState, payload: IMDA  | null) {
        state.ehealth_patient_data = payload;
    },
    setCountEPathology(state: PatientState, payload: number) {
        state.count_e_pathology = payload;
    },
    setCountFaPathology(state: PatientState, payload: number) {
        state.count_fa_pathology = payload;
    },
    setCountFbPathology(state: PatientState, payload: number) {
        state.count_fb_pathology = payload;
    },
    setDocumentsForPatient(state: PatientState, payload: {patientId: number, docs: IDocument[]}) {

        const patients = state.patients.filter((patient: IPatient) => patient.id !== payload.patientId);
        let patient = state.patients.filter((patient: IPatient) => patient.id == payload.patientId)[0];
        patient.documents = payload.docs
        patients.push(patient);
        state.patients = patients;
    },
};

const { commit } = getStoreAccessors<PatientState, State>('');

export const commitSetPatient = commit(mutations.setPatient);
export const commitClearPatients = commit(mutations.clearPatients)
export const commitSetPatients = commit(mutations.setPatients);
export const commitSetComment = commit(mutations.setComment);
export const commitSetComments = commit(mutations.setComments);
export const commitSetPatientData = commit(mutations.setPatientData);
export const commitSetCountEPathology = commit(mutations.setCountEPathology);
export const commitSetCountFaPathology = commit(mutations.setCountFaPathology);
export const commitSetCountFbPathology = commit(mutations.setCountFbPathology);
export const commitSetDocumentsForPatient = commit(mutations.setDocumentsForPatient)