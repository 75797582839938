import { INomenclature } from '@/interfaces';
import { DocumentState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setThirdPartyPayment(state: DocumentState, payload: Blob) {
        state.third_party_payment = payload;
    },
};

const { commit } = getStoreAccessors<DocumentState, State>('');

export const commitSetThirdPartyPayment = commit(mutations.setThirdPartyPayment);
