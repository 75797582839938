import { PatientState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    Patients: (state: PatientState) => state.patients.filter((patient) => patient.disabled !== true),
    PatientsInclDisabled: (state: PatientState) => state.patients,
    DisabledPatients: (state: PatientState) => state.patients.filter((patient) => patient.disabled === true),
    OnePatient: (state: PatientState) => (patientId: number) => {
        const filteredPatients = state.patients.filter((patient) => patient.id === patientId);
        if (filteredPatients.length > 0) {
            return { ...filteredPatients[0] };
        }
    },
    Comments: (state: PatientState) => state.patient_comments,
    OnePatientComments: (state: PatientState) => (patientId: number) => {
        const filteredComments = state.patient_comments.filter((comment) => comment.patient_id === patientId);
        return filteredComments
    },
    PatientData: (state: PatientState) => state.ehealth_patient_data,
    CountEPathology: (state: PatientState) => state.count_e_pathology,
    CountFaPathology: (state: PatientState) => state.count_fa_pathology,
    CountFbPathology: (state: PatientState) => state.count_fb_pathology,
};

const { read } = getStoreAccessors<PatientState, State>('');

export const readOnePatient = read(getters.OnePatient);
export const readPatients = read(getters.Patients);
export const readPatientsInclDisabled = read(getters.PatientsInclDisabled);
export const readDisabledpatients = read(getters.DisabledPatients);
export const readOnePatientComments = read(getters.OnePatientComments);
export const readComments = read(getters.Comments);
export const readPatientData = read(getters.PatientData);
export const readCountEPathology = read(getters.CountEPathology);
export const readCountFaPathology = read(getters.CountFaPathology);
export const readCountFbPathology = read(getters.CountFbPathology);