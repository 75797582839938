<template>
    <div>
        <v-snackbar auto-height :color="currentNotificationColor" :timeout="notificationTimeout" v-model="show">
            <v-progress-circular class="ma-2" indeterminate v-show="showProgress"></v-progress-circular>
            <div v-if="!showDetail">
                {{ currentNotificationContent }}
            </div>
            
            <ul v-if="showDetail && detail">
                <li v-for="d in detail">
                    Fout:
                    <ul>
                        <li v-for="(item, key) in d">
                            {{ key }}: {{ item }}
                        </li>
                    </ul>
                </li>

            </ul>

            <v-btn id="detailNotification" v-if="url" flat @click="goto">Voorschrift aanmaken</v-btn>
            <v-btn id="detailNotification" v-if="detail && !showDetail" flat @click.native="showDetail=true">Detail</v-btn>
            <v-btn id="closeNotification" flat @click.native="close">Sluiten</v-btn>

        </v-snackbar>
    </div>
</template>
<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { AppNotification } from '@/store/main/state';
import { commitRemoveNotification } from '@/store/main/mutations';
import { readFirstNotification } from '@/store/main/getters';

@Component
export default class NotificationsManager extends Vue {
    public show: boolean = false;
    public text: string = '';
    public detail: [{ [name: string]: string}] | null = null;
    public url: string | null = null;
    public showProgress: boolean = false;
    public showDetail: boolean = false;
    public currentNotification: AppNotification | false = false;

    public async hide() {
        this.show = false;
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500));
    }

    public async goto() {
        if (this.url){
            this.$router.push(this.url);
        }
    }

    public async close() {
        await this.hide();
        await this.removeCurrentNotification();
        this.showDetail = false;
    }

    public async removeCurrentNotification() {
        if (this.currentNotification) {
            commitRemoveNotification(this.$store, this.currentNotification);
            this.currentNotification = false;
        }
    }

    public get firstNotification() {
        return readFirstNotification(this.$store);
    }

    public async setNotification(notification: AppNotification | false) {
        if (this.show) {
            await this.hide();
        }
        if (notification) {
            this.currentNotification = notification;
            this.showProgress = notification.showProgress || false;
            this.show = true;
            this.detail = notification.detail ?? null;
            this.url = notification.url ?? null
            this.showDetail = false;
        } else {
            this.currentNotification = false;
        }
    }

    @Watch('firstNotification')
    public async onNotificationChange(
        newNotification: AppNotification | false,
        oldNotification: AppNotification | false,
    ) {
        await this.removeCurrentNotification();
        await this.setNotification(newNotification);
    }

    public get currentNotificationContent() {
        return this.currentNotification && this.currentNotification.content || '';
    }

    public get currentNotificationColor() {
        return this.currentNotification && this.currentNotification.color || 'info';
    }

    public get notificationTimeout() {
        if (this.currentNotification && this.currentNotification.color == "error"){
            return 60000
        }
        else {
            return 1000
        }
    }
}
</script>
