export function parse_error_message(error_detail: any, request_type?: string){
    if (request_type == undefined){
        request_type == "Aanvraag"
    }
    console.log("error_detail")
    console.log(error_detail)
    if (typeof error_detail === 'string' || error_detail instanceof String){
        return error_detail
    }

    if (error_detail.detail && error_detail.detail.length <= 2){
        let single_error = error_detail.detail[0];
        console.log("single_error")
        console.log(single_error)
        if (single_error.event_code == "reject"){
            console.log("rejected");
            let detail_code = single_error.detail_code;
            if (detail_code == "UNAUTHORIZED_STARTDATE_IN_CLAIM_BILLABLEPERIOD"){
                detail_code = "Startdatum akkoord is ongeldig"
            } else if (detail_code == "UNAUTHORIZED_CLAIM_DUE_TO_EXISTING_IN_PROCESS_CLAIM"){
                detail_code = "Er is reeds een gelijkaardige aanvraag in behandeling"
            } else if (detail_code == "INVALID_SERVICEREQUEST_PRESCRIPTIONDATE"){
                detail_code = "Datum voorschrift is ongeldig"
            } else if (detail_code == "INVALID_CLAIM_SERVICEDATE"){
                detail_code = "Datum verschijnen aandoening is ongeldig"
            } else if (detail_code == "MISSING_PRESCRIPTION_IN_PHYSIO_CLAIM"){
                detail_code = "Voorschrift is verplicht mee te sturen"
            } else if (detail_code == "MISSING_ANNEX_OR_PRESCRIPTION_IN_PHYSIO_CLAIM"){
                detail_code = "Extra bijlagen nodig (annex of voorschrift)"
            } else if (detail_code == "MISSING_MEDICALREPORT_ANNEX_IN_PHYSIO_CLAIM_SUPPORTINGINFO"){
                detail_code = "Medisch verslag is verplicht mee te sturen"
            } else if (detail_code == "MISSING_RADIOLOGY_REPORT_ANNEX_IN_PHYSIO_CLAIM_SUPPORTINGINFO"){
                detail_code = "Rapport radiologie is verplicht mee te sturen"
            } else if (detail_code == "MISSING_RADIOLOGY_PROTOCOL_ANNEX_IN_PHYSIO_CLAIM_SUPPORTINGINFO"){
                detail_code = "Radiologieprotocol is verplicht mee te sturen"
            } else if (detail_code == "UNAUTHORIZED_CLAIM_PREAUTHREF_INVALID_SUBTYPE"){
                detail_code = "Enkel Fb, E en 11bis akkoorden kunnen worden verlengd"
            } else if (detail_code == "UNAUTHORIZED_CLAIM_DUE_TO_EXISTING_AGREEMENT"){
                detail_code = "Er bestaat reeds een gelijkaardig akkoord"
            } else if (detail_code == "UNAUTHORIZED_CLAIM_PREAUTHREF_WRONG_CAREPROVIDER"){
                detail_code = "Argumentatie of annulatie kan enkel door de aanvrager gebeuren"
            } else if (detail_code == "MISSING_ARGUMENTATION_IN_CLAIM"){
                detail_code = "Ontbrekende argumentatie (minimaal 1 van volgende documenten moet worden meegestuurd: huidig voorschrift, vorig voorschrift, bijlage, bericht met bijkomende info)"
            } else if (detail_code == "UNKNOWN_CLAIM_PREAUTHREF"){
                detail_code = "Onbekende referentie"
            } else if (detail_code == "UNAUTHORIZED_CLAIM_PREAUTHREF_NOT_EXTENDABLE"){
                detail_code = "Referentie kan niet verlengd worden"
            } else if (detail_code == "UNAUTHORIZED_CLAIM_PREAUTHREF_INVALID_PERIOD"){
                detail_code = "Ongeldige periode voor verlenging"
            } else if (detail_code == "INCONSISTENT_STARTDATE_IN_CLAIM_BILLABLEPERIOD"){
                detail_code = "Startdatum verlenging moet na bestaand akkoord liggen"
            } else if (detail_code == "INVALID_STARTDATE_IN_CLAIM_BILLABLEPERIOD"){
                detail_code = "Startdatum moet dag na bestaand akkoord liggen"
            } else if (detail_code == "UNAUTHORIZED_CLAIM_PREAUTHREF_NOT_CANCELLABLE"){
                detail_code = "Aanvraag kan niet geannuleerd worden"
            } else if (detail_code == "UNAUTHORIZED_ADDITIONALINFO_IN_PHYSIO_CLAIM_SUPPORTINGINFO"){
                detail_code = "Meesturen bijkomende info enkel toegestaan voor aanvraag 2de akkoord, of voor aanvraag e-pathologie"
            } else if (detail_code == "UNAUTHORIZED_AGREEMENT_TYPE_WITH_PATIENT_SITUATION"){
                detail_code = "Er bestaat reeds een akkoord voor deze pathologie"
            } else if (detail_code == "MISSING_ANNEX_OR_PRESCRIPTION_IN_PHYSIO_CLAIM "){
                detail_code = "Gelieve een medisch rapport of rapport kinesist mee te sturen, en bij voorkeur ook kopie van vorig voorschrift"
            }  else if (detail_code == "UNKNOWN_PRACTITIONER_IDENTIFIER"){
                detail_code = "Foutief RIZIV nummer dokter, gelieve voorschrift na te zien"
            } else if (detail_code == "MISSING_MEDICALREPORT_ANNEX_IN_PHYSIO_CLAIM_SUPPORTINGINFO"){
                detail_code = "Gelieve een medisch rapport mee te sturen"
            } else if (detail_code == "MISSING_SCOLIOSIS_PROTOCOL_ANNEX_IN_PHYSIO_CLAIM_SUPPORTINGINFO"){
                detail_code = "Gelieve een protocol scoliosis mee te sturen"
            } else if (detail_code.includes("but must be 'application/pdf'")){
                detail_code = "Enkel PDF bijlagen toegestaan."
            }
            return `${request_type} geweigerd: ${detail_code}`;
        } else if (single_error.event_code == "refusal"){
            console.log("rejected");
            let detail_code = single_error.detail_code;
            if (detail_code == "REF_AGREE_SRV_PHYSIO_010"){
                detail_code = "Weigering omwille van leeftijd patiënt"
            } else if (detail_code == "REF_AGREE_SRV_PHYSIO_015"){
                detail_code = "Weigering omwille van leeftijd patiënt"
            } else if (detail_code == "REF_AGREE_SRV_PHYSIO_001"){
                detail_code = "Weigering omwille van bestaand akkoord"
            } else if (detail_code == "REF_AGREE_SRV_PHYSIO_004"){
                detail_code = "Weigering omdat er al teveel lopende pathologie-reeksen zijn voor het huidige jaar."
            } else if (detail_code == "REF_AGREE_SRV_PHYSIO_014"){
                detail_code = "Weigering omwille van leeftijd patiënt"
            }
            return `${request_type} geweigerd: ${detail_code}`;
        } else if (single_error.event_code == "search-type"){
            console.log("bundle issue");
            let diagnostics_code = single_error.diagnostics_code.split("(")[0];
            return `Fout in aanvraag: ${diagnostics_code}`;
        }
    }
    return error_detail.message ?? "";
}