import { INomenclature, INonConventionalHonorarium } from '@/interfaces';
import { NomenclatureState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setNomenclatures(state: NomenclatureState, payload: INomenclature[]) {
        state.nomenclatures = payload;
    },
    setNonConventionalHonoraria(state: NomenclatureState, payload: INonConventionalHonorarium[]) {
        state.non_conv_honoraria = payload;
    },
    setNomenclature(state: NomenclatureState, payload: INomenclature) {
        const nomenclatures = state.nomenclatures.filter((nomenclature: INomenclature) => nomenclature.id !== payload.id);
        nomenclatures.push(payload);
        state.nomenclatures = nomenclatures;
    },
    setNonConventionalHonorarium(state: NomenclatureState, payload: INonConventionalHonorarium) {
        const non_conv_honoraria = state.non_conv_honoraria.filter((honorarium: INonConventionalHonorarium) => honorarium.id !== payload.id);
        non_conv_honoraria.push(payload);
        state.non_conv_honoraria = non_conv_honoraria;
    },
    removeNonConventionalHonorarium(state: NomenclatureState, payload: number) {
        state.non_conv_honoraria = state.non_conv_honoraria.filter((honorarium: INonConventionalHonorarium) => honorarium.id !== payload);
    }
};

const { commit } = getStoreAccessors<NomenclatureState, State>('');

export const commitSetNomenclature = commit(mutations.setNomenclature);
export const commitSetNomenclatures = commit(mutations.setNomenclatures);
export const commitSetNonConventionalHonoraria = commit(mutations.setNonConventionalHonoraria)
export const commitSetNonConventionalHonorarium = commit(mutations.setNonConventionalHonorarium)
export const commitRemoveNonConventionalHonorarium = commit(mutations.removeNonConventionalHonorarium)