export const translateError = (error_message: string) => {
    if (error_message == "First appointment cannot be more than 2 months after prescription date"){
        return "Eerste afspraak kan niet meer dan 2 maanden na datum voorschrift liggen"
    }
    if (error_message == "Appointment cannot be more than 2 months after last prescription appointment"){
        return "Afspraak kan niet meer dan 2 maanden na laatste afspraak op voorschrift liggen"
    }
    return error_message
}

export const patientFilter = (item, queryText, itemText) => {
    const textOne = item.first_name.toLowerCase();
    const textTwo = item.last_name.toLowerCase();
    const searchText = queryText.toLowerCase();

    return textOne.indexOf(searchText) > -1 ||
      textTwo.indexOf(searchText) > -1;
  }