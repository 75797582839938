import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import { mainModule } from './main';
import { State } from './state';
import { adminModule } from './admin';
import { nomenclatureModule } from './nomenclatures';
import { patientModule } from './patients';
import { prescriptionModule } from './prescriptions';
import { appointmentModule } from './appointments';
import { documentModule } from './documents';
import { attestationModule } from './attestations';
import { invoiceModule } from './invoices';
import { financialsModule } from './financials';

Vue.use(Vuex);

const storeOptions: StoreOptions<State> = {
  modules: {
    main: mainModule,
    admin: adminModule,
    nomenclatures: nomenclatureModule,
    patients: patientModule,
    prescriptions: prescriptionModule,
    appointments: appointmentModule,
    documents: documentModule,
    attestations: attestationModule,
    invoices: invoiceModule,
    finanicals: financialsModule,
  },
};

export const store = new Vuex.Store<State>(storeOptions);

export default store;
