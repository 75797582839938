import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { PatientState } from './state';

const defaultState: PatientState = {
  patients: [],
  patient_comments: [],
  ehealth_patient_data: null,
  count_e_pathology: null,
  count_fa_pathology: null,
  count_fb_pathology: null,
};

export const patientModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
