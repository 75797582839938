import { api } from '@/api';
import { ActionContext } from 'vuex';
import { IPrescriptionCreate, IPrescriptionUpdate, IPrescription } from '@/interfaces';
import { State } from '../state';
import { PrescriptionState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetPrescription, commitSetDocumentsForPrescription, commitRemovePrescription, commitSetPrescriptions, commitClearPrescriptions} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';

type MainContext = ActionContext<PrescriptionState, State>;

export const actions = {
    async actionGetPrescriptions(context: MainContext) {
        try {
            commitClearPrescriptions(context)
            let skip = 0;
            let limit = 100
            let results: IPrescription[] = []
            
            while (true){
                const response = await api.getPrescriptions(context.rootState.main.token, skip=skip, limit=limit);
                if (response) {
                    if (response.data.length == 0){
                        break
                    }
                    results.push(...response.data)
                    await commitSetPrescriptions(context, results)
                    skip += limit;
                }
            }

        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdatePrescription(context: MainContext, payload: { id: number, prescription: IPrescriptionUpdate }) {
        try {
            const loadingNotification = { content: 'opslaan', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updatePrescription(context.rootState.main.token, payload.id, payload.prescription),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetPrescription(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Voorschrift aangepast', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionDeletePrescription(context: MainContext, payload: { id: number }) {
        try {
            const loadingNotification = { content: 'verwijderen', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.deletePrescription(context.rootState.main.token, payload.id),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemovePrescription(context, payload.id);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Voorschrift verwijderd', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreatePrescription(context: MainContext, payload: IPrescriptionCreate) {
        try {
            const loadingNotification = { content: 'opslaan', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createPrescription(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetPrescription(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Voorschrift aangemaakt', color: 'success' });
            return response.data
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    //Documents
    async addDocument(context: MainContext, payload: { prescriptionId: number, file: File }) {
        try {
            const response = await api.addDocumentToPrescription(context.rootState.main.token, payload.prescriptionId, payload.file);
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async getDocuments(context: MainContext, payload: { prescriptionId: number }) {
        if (payload.prescriptionId === undefined){
            return
        }
        try {
            const response = await api.getDocumentsForPrescription(context.rootState.main.token, payload.prescriptionId);
            if (response) {
                commitSetDocumentsForPrescription(context, {prescriptionId: payload.prescriptionId, docs: response.data});
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    //eHealth
    async getAddressBook(context: MainContext, payload: { 
        nihii: string,
    }) {
        try {
            const response = (await Promise.all([
                api.getAddressBook(context.rootState.main.token, payload.nihii),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            console.log(response.data)
            return response.data
        } catch (error: any) {
            if (error.response){
                let error_message = error.response.data.detail.message ?? "";
                if (error.response.status == 401){
                    error_message = "eHealth login ongeldig, gelieve opnieuw in te loggen op de Profiel-pagina."
                }
                if (error.response.status == 400){
                    error_message = "Kon gegevens dokter niet ophalen uit eHealth.  Is het RIZIV nr geldig?"
                }
                let error_message_detail = error.response.data.detail.detail;
                console.log(error_message)
                commitAddNotification(context, { color: 'error', content: error_message, detail: error_message_detail });
            }
        }
    },
    async searchAddressBook(context: MainContext, payload: { 
        last_name_search: string,
    }) {
        try {
            const response = (await Promise.all([
                api.searchAddressBook(context.rootState.main.token, payload.last_name_search),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            console.log(response.data)

            if (response.data.length == 0){
                let error_message = "Geen zoekresultaten"
                commitAddNotification(context, { color: 'error', content: error_message });
            }
            return response.data
        } catch (error: any) {
            if (error.response){
                let error_message = error.response.data.detail.message ?? "";
                if (error.response.status == 401){
                    error_message = "eHealth login ongeldig, gelieve opnieuw in te loggen op de Profiel-pagina."
                }
                if (error.response.status == 400){
                    error_message = "Zoeken in eHealth adresboek mislukt."
                }
                let error_message_detail = error.response.data.detail.detail;
                console.log(error_message)
                commitAddNotification(context, { color: 'error', content: error_message, detail: error_message_detail });
            }
        }
    },
};

const { dispatch } = getStoreAccessors<PrescriptionState, State>('');

export const dispatchCreatePrescription = dispatch(actions.actionCreatePrescription);
export const dispatchGetPrescriptions = dispatch(actions.actionGetPrescriptions);
export const dispatchUpdatePrescription = dispatch(actions.actionUpdatePrescription);
export const dispatchDeletePrescription = dispatch(actions.actionDeletePrescription);

export const dispatchAddDocumentToPrescription = dispatch(actions.addDocument);
export const dispatchGetDocumentsForPrescription = dispatch(actions.getDocuments);
export const dispatchGetAddressBook = dispatch(actions.getAddressBook)
export const dispatchSearchAddressBook = dispatch(actions.searchAddressBook)
