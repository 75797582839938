import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { AppointmentState } from './state';

const defaultState: AppointmentState = {
  appointments: [],
  unconfirmed_appointments: [],
  prescriptions: [],
  unsynced_events: [],
  table: [],
  split_proposals: [],
};

export const appointmentModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
